<template>
  <v-alert
    :name="name"
    :value="$store.getters['get_global_successStatus']"
    type="success"
    dismissible
    elevation="3"
    transition="fade-transition"
    origin="center center"
    class="mt-4 mb-0"
    v-if="$store.getters['get_global_success']"
    @input="$store.commit('global_clearSuccess')"
  >
    <span v-if="$store.getters['get_global_success'] !== null && isObj">
      {{ $t($store.getters["get_global_success"].message) }}
    </span>
    <span v-else>{{ $t(successText) }}</span>
  </v-alert>
</template>
<script>
import { isObject } from "@/helpers/helpers";

export default {
  name: "AlertSuccess",
  props: ["successText", "name"],
  computed: {
    isObj() {
      return isObject(this.$store.getters["get_global_success"]);
    }
  }
};
</script>
